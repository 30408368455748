import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HistoryContext } from 'contexts/history';
import { UserSidebar, Avatar, OutsideClickHandler, Slider, UserDropdownMenu } from 'components';
import {
	Notifications,
	Suggestions,
	Favorites,
	Cart,
	Tnets,
	BottomBar,
} from '../components'

import { SwiperSlide } from 'swiper/react';
import { getSwiperConfig } from 'core';
import { AppDataContext } from 'contexts/appData';

import { MobileCategories } from '../components';
import { NewVersionBar } from "../components/NewVersionBar";

import { SearchIcon } from '../../../components/icons';
import { UserContext } from '../../../contexts/user';

const Header = (lib) => {
	const sliderOneRef = useRef(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const mobileToggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const toggleMenu = (e, action = null) => {
		e && (e.preventDefault() || e.stopPropagation());
		let body = document.body.classList;
		if (action !== null) {
			body.add('filter-open')
		} else {
			body.remove('filter-open')
		}
	};

	const {appData} = useContext(AppDataContext);
	const {langs, getLang} = lib;
	const [category, setCategory] = useState(null)
	const {prefix} = useContext(AppDataContext);
	const {params, push} = useContext(HistoryContext)
	const { user } = useContext(UserContext);



	const [isActive, setIsActive] = useState(false);
	const sidebarRef = useRef(null);


	const handleMenuClick = () => {
		setIsActive(!isActive);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (!event.target.closest('.menu-cont')) {
				setIsActive(false);
			}
		};

		if (isActive) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isActive]);


	const getList = (CatID) => {
		let workingCategory = CatID ? CatID : 0
		if (workingCategory !== 0 && appData.Cats.List[workingCategory].SubCatCount === 0) {
			workingCategory = appData.Cats.List[workingCategory].ParentId
		}
		setCategory(workingCategory)
	}

	useEffect(() => {
		getList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const searchByCategory = (CatID) => {
		setTimeout(() => {
			// push(`/${getLang}/search${prefix(CatID)}`, {CatID: CatID}, true);
			window.location.href = `/${getLang}/search${prefix(CatID)}?CatID=${CatID}`;
		}, 200)
	}

	const categorySelect = (e, CatID) => {
		e.preventDefault();
		e.stopPropagation();
		if (CatID !== 0 && appData.Cats.List[CatID] && appData.Cats.List[CatID].SubCatCount === 0) {
			toggleMenu(e);
			searchByCategory(CatID)
		} else {
			getList(CatID)
		}
	}


	const goToCategory = (e, CatID) => {
		e.preventDefault();
		e.stopPropagation();
		toggleMenu(e);
		searchByCategory(CatID)
	}

	const navigationLinksData = [
		{
			text: lib.shop?.ID ? 'MyShop' : 'OpenShop',
			href: '/shop',
		},
		{
			text: 'Help',
			href: '/faq',
		},
		{
			text: 'Contact',
			href: '/contact',
		},
		{
			text: 'NM_WriteCallRequest',
			href: '#',
			onClick: lib.callLivecallerMessage,
		},
		{
			text: 'CallRequest',
			href: '#',
			onClick: lib.callLivecallerPhone,
		},
		{
			text: 'NM_ReviewCount',
			href: 'https://www.surveymonkey.com/r/83DHMLK',

		},
		{
			text: 'Safety_menuList',
			href: '/safetylanding',
		},
		{
			text: 'SiteRules',
			href: 'https://auth.tnet.ge/ka/rules',
		},
	];


	const navigationHeader = [
		{
			text: 'NM_SecondHandInInstallments',
			href: '/search?CondTypeID=0&Installment=1&Page=1',
			reload: true,
		},
		{
			text: 'TradeIn',
			href: '/tradein',
		},
		{
			text: 'APP_shops',
			href: '/shops',
			reload: true,
		},
		{
			text: 'NM_Discount',
			href: '/search/?Discount=1&Page=1',
			reload: true,
		},

		// {
		// 	text: 'OfferPrice',
		// 	href: '/search?OfferPrice=1&Online=1&Page=1',
		// },


	];
	const navigationHeaderStatic = [
		{
			text: lib.shop?.ID ? 'MyShop' : 'OpenShop',
			href: '/shop',
		},
		{
			text: 'Help',
			href: '/faq',
		},
		{
			text: 'Contact',
			href: '/contact',
			reload: true,
		},
	];


	const Language = [
		{text: lib.langs.LanguagesNames['ka'], Name: 'ka', Link: lib.changeLang('ka'),},
		{text: lib.langs.NM_English, Name: 'en', Link: lib.changeLang('en')},
		{text: lib.langs.NM_russian, Name: 'ru', Link: lib.changeLang('ru')},
	]

	const location = useLocation()
	const lang = window.location.pathname.split('/')[1];
	const showNewVersionBar = `/${lang}` === location.pathname || `/${lang}/` === location.pathname

	return (
			<header>
				{/* <TopInfo  {...lib.langs} /> */}
				{/*<TopOldVersion {...{langs}} />*/}

				{showNewVersionBar && <NewVersionBar/>}

      <div className="header-top-content">
        <div className="container">
          <div className="row">
            <div className="header-top col-12 px-md-0 position-relative w-100 d-flex align-items-center justify-content-between bg-white">
              <div className="d-flex flex-1 align-items-center">
                <div className={`logo-content d-flex align-items-center justify-content-between w-100 ${lib.activeSearch ? 'active' : ''}`}>
                  <Link reloadDocument className="logo_ mr-0px mr-sm-28px d-flex align-items-center justify-content-center h-100" to={`/${lib.getLang}`}>
                    <i className="svg-i logo-i"></i>
                  </Link>
                  <OutsideClickHandler {...lib.searchParams}>
                    <form
                      className={`search-form d-flex border mr-28px border-bordergray-20 search-block position-relative flex-grow-1 w-100 w-md-280px w-m-320px ${
                        lib.mobileSearch ? 'active' : ''
                      }`}
                      onSubmit={lib.mainSearch}
                    >
                      {/*<div className="img-upload" onClick={lib.callImageUpload}></div>*/}
                      <input
                        type="text"
                        className={`h-100 w-100 border-0 font-size-14 placeholder:font-size-14 pr-md-100px pr-36px`}
                        enterKeyHint="search"
                        {...lib.searchInputProps}
                      />
                      {/*{lib.searchImage && (*/}
                      {/*  <div className="d-flex align-items-center justify-content-between position-absolute min-height-28px min-width-80px left-2px transformY-50  h-34px bg-gray-4 border-radius-sm border border-dark-purple-blue ">*/}
                      {/*    <img*/}
                      {/*      src={lib.searchImage.image}*/}
                      {/*      alt=""*/}
                      {/*      className="w-20px h-20px border-radius-5px object-fit-cover ml-10px mr-2px my-0 mx-md-8px my-md-8px"*/}
                      {/*    />*/}
                      {/*    <div onClick={lib.clearSearchImage} className="cursor-pointer p-4px mr-10px">*/}
                      {/*      <i className="svg-i close-i w-20px h-20px"></i>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      {lib.suggestions.length > 0 && lib.activeSearch && <Suggestions {...lib.suggestionsProps} />}
                      <button
                        type="submit"
                        className="search-icon position-absolute border-0 align-items-center d-flex align-items-center justify-content-center"
                      >
                        {/*<i className="svg-i search-i-mobile w-20px h-20px"></i>*/}
												<SearchIcon width={20} height={20} fill={'#757b8c'} />
                      </button>
                    </form>
                  </OutsideClickHandler>
                  <div className="mobile-search-btn cursor-pointer mr-4px" onClick={lib.openSearch}>
                    <i className="svg-i search-i-mobile w-20px h-20px"></i>
                  </div>
                </div>
                {/* {(activeSearch && suggestions && suggestions.length > 0) && <div className="back-drop"></div> } */}
              </div>
              <ul className="navbar-nav flex-row align-items-center ml-4px m-hidden">
                <button className="btn d-flex align-items-center add-statement-btn m-hidden" onClick={lib.openAddDialog}>
                  <i className="svg-i header-add-icon mr-8px flex-shrink w-21px h-21px"></i>
                  <span className="font-medium font-size-12 line-height-13">{lib.langs.Add}</span>
                </button>
                <div className="mx-8px m-hidden"></div>
                <Notifications {...lib.notificationsProps} />
                <Favorites {...{ getLang: lib.getLang }} />
                <Cart {...{ getLang: lib.getLang }} />
                {!lib.user ? (
                  <li
                    className="btn nav-item d-none d-md-flex align-items-center border border-radius-sm auth-btn ml-24px h-42px hover"
					style={{padding: '9px 12px'}}
                    onClick={lib.authorization}
                  >
                    <i style={{width: '22px', height: '22px'}} className="svg-i svg-i-lg auth-i mr-8px" ></i>
                    <span className="text-dark font-medium font-size-14 bg-transparent p-0 text-decoration-none">{lib.langs.LogIn}</span>
                  </li>
                ) : (
                  <>
                    <div
                      className={`d-md-flex border border-radius-sm ml-24px hover-info position-relative transition-all d-none cursor-pointer authorised hover menu-cont ${
                        isActive ? 'active' : ''
                      } `}
					  style={{ height: '42px', padding: '9px 12px'}}
                      onClick={handleMenuClick}
                    >
                      <OutsideClickHandler {...lib.userDropdownOutsideClickHandlerParams}>
                        <div className="d-flex align-items-center cursor-pointer mobile-hidden" onClick={(e) => lib.toggleUserDropdown(e)}>
                          <li className={`nav-item d-flex position-relative header-user dropdown hover ${lib.userDropdown ? 'show' : ''}`}>
                            <Avatar {...lib.avatarProps} isLoggedIn={!!user} />
                            {/* <div className={`dropdown-menu dropdown-menu-right bg-transparent border-0 position-absolute ${lib.userDropdown ? 'show' : ''}`}>
															<UserDropdown {...lib.userDropdownProps} />
															<div className="svg_arrow"></div>
														</div> */}
                          </li>
                        </div>
                      </OutsideClickHandler>
                      <div className="user-info ml-8px mr-8px d-none d-md-flex flex-column text-decoration-none align-self-center">
                        <span className="font-size-14 font-medium text-dark">{lib.user && lib.user.Name}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="svg-i svg-i-sm header-dropdown-arrow w-8px h-6px"></i>
                      </div>
                      <div
                        className={`position-absolute w-250px left-0 sidebar-hover top-100 pt-6px z-index-999 d-none${isActive ? 'active' : ''} `}
                        ref={sidebarRef}
                      >
                        <div className={`border-radius-md bg-white shadow `}>
                          <UserDropdownMenu {...lib.userDropdownProps} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* <OutsideClickHandler {...lib.menuDropdownOutsideClickHandlerParams}>
									<li className={`nav-item d-none d-md-flex cursor-pointer right-menu-header m-hidden px-8px py-10px   position-relative dropdown ${lib.menuDropdown ? 'show' : ''}`} onClick={lib.toggleMenuDropdown}>
										<i className="svg-i  menu-i"></i>
										<div className={`dropdown-menu dropdown-menu-right bg-transparent border-0 position-absolute ${lib.menuDropdown ? 'show' : ''}`}>
											<UserMenu {...lib.userMenuProps} />
											<div className="svg_arrow"></div>
										</div>
									</li>
								</OutsideClickHandler> */}
									<OutsideClickHandler {...lib.tnetDropdownOutsideClickHandlerParams}>
										<Tnets {...lib.tnetProps} />
									</OutsideClickHandler>
								</ul>
								<button type="button"
												className="btn burger-mobile rounded-circle p-1px  py-md-10px m-show position-reletive"
												onClick={mobileToggleMenu}>
									<i className="svg-i  menu-i "></i>
									{/*{lib.notifications?.unseen_count > 0 &&*/}
									{/*		<span*/}
									{/*				className="rounded-circle badge badge-pill bg-dark-orange font-base text-white position-absolute badge-counter badge-burger">*/}
									{/*	{lib.notifications.unseen_count}*/}
									{/*</span>*/}
									{/*}*/}
								</button>
								{isMenuOpen && (
										<div className="menu-container">
											<div className="menu-content px-30px pt-24px">
												<div className="d-flex justify-content-between align-items-center pb-17px">
													<span className='font-size-12 text-blue-gray'>{langs.Menu}</span>
													<span onClick={mobileToggleMenu} className="close-button cursor-pointer">
												<i className="svg-i close-bg-i w-40px h-40px"></i>
											</span>
												</div>
												<ul>
													{navigationLinksData.map((link, index) => (
															<li key={index}>
																<a
																		href={link.href}
																		target={link.text === 'NM_ReviewCount' ? '_blank' : undefined}
																		rel={link.text === 'NM_ReviewCount' ? 'noopener noreferrer' : undefined}
																		className="text-decoration-none cursor-pointer text-dark font-size-16 d-flex py-8px"
																		onClick={(e) => {
																			if (link.onClick) {
																				link.onClick(e);
																			}
																			mobileToggleMenu();
																		}}
																>
																	{langs[link.text]}
																</a>
															</li>
													))}
												</ul>
												<hr/>
												<div className="d-flex pt-8px">
													{Language.map((item, i) => (

															<a key={i} href={item.Link} className={`text-decoration-none text-dark font-size-12 d-flex align-items-center justify-content-start py-10px px-8px border border-radius mr-8px
												${lib.getLang === item.Name ? 'langs-card' : ''} `}>
																{item.text}
															</a>
													))}
												</div>
											</div>

										</div>
								)}
								{lib.mobileSidebar &&
										<UserSidebar {...lib.userSidebarProps} />
								}
							</div>
							<div className='d-flex justify-content-between w-100 overflow-hidden'>
								<div
										className="slider-tabs d-flex align-items-center justify-content-between header-swiper px-0 pb-16px px-16px px-md-0">
									<Slider {...getSwiperConfig('newHeaderSwiper')} customSwiper ref={sliderOneRef}>
										{navigationHeader.map((link, index) => (
												<SwiperSlide className={'max-width-md-170px w-auto mr-32px header-swiper-hover'} key={index}>
													<Link   reloadDocument={link.reload}
															to={`/${lib.getLang}${link.href}`}
															className="text-decoration-none cursor-pointer font-base text-dark font-size-14 d-flex align-items-center py-8px"
															onClick={
																link.onClick ? link.onClick : null
															}
													>
														{langs[link.text]}
														{ link.text === 'NM_Discount' &&
															<i className="svg-i header-cats-sale w-16px h-16px ml-4px"></i>
														}
													</Link>
												</SwiperSlide>
										))}
									</Slider>

								</div>
								<div className='d-none d-lg-flex '>
									{navigationHeaderStatic.map((link, index) => (
											<div className='max-width-md-170px w-auto mr-32px header-swiper-hover' key={index}>
												<Link
														to={`/${lib.getLang}${link.href}`}
														className="text-decoration-none cursor-pointer font-base text-dark font-size-14 d-flex py-8px text-nowrap"
														onClick={
															link.onClick ? link.onClick : null
														}
														reloadDocument={link.hardReload}
												>
													{langs[link.text]}
												</Link>
											</div>
									))}
								</div>
							</div>

						</div>
					</div>
				</div>
				<BottomBar
						lib={lib}
						toggleMenu={toggleMenu}
				/>

				<MobileCategories
						{...{
							toggleMenu,
							langs: langs,
							appData: appData,
							categorySelect,
							goToCategory,
							category,
							params: params
						}} />

			</header>
	)
}
export default Header
