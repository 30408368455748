import {lazy} from 'react';
import EmptyLayout from '../components/layouts/EmptyLayout';

export { Header } from './header'
export { Footer } from './footer'

export const RouteList = [
	{
		index: true,
		path: '/:locale/',
		Component: lazy(() => import('./home/views/HomeLib.js'))
	},
	{
		path: '/:locale/shops/:shopId',
		Component: lazy(() => import('./search/views/SearchLib.js'))
	},
	{
		path: '/:locale/users/:userId',
		Component: lazy(() => import('./search/views/SearchLib.js'))
	},
	{
		path: '/:locale/shops/*',
		Component: lazy(() => import('./shops/views/Shops'))
	},
	{
		path: '/:locale/myshop/*',
		Component: lazy(() => import('./myshop/views/MyShop'))
	},
	{
		private: true,
		path: '/:locale/pr-form/',
		Component: lazy(() => import('./productForm/views/ProductFormLib.js'))
	},
	{
		private: true,
		path: '/:locale/pr-form/:id/*',
		Component: lazy(() => import('./productForm/views/ProductFormLib.js'))
	},
	{
		path: '/:locale/search/',
		Component: lazy(() => import('./search/views/SearchLib.js'))
	},
	{
		path: '/:locale/search/:id/',
		Component: lazy(() => import('./search/views/SearchLib.js'))
	},
	{
		path: '/:locale/search/:id/:category/',
		Component: lazy(() => import('./search/views/SearchLib.js'))
	},
	{
		path: '/:locale/search/:id/:category/:subcategory/',
		Component: lazy(() => import('./search/views/SearchLib.js'))
	},
	{
		path: '/:locale/search/:id/:category/:subcategory/:targetcategory/*',
		Component: lazy(() => import('./search/views/SearchLib.js'))
	},
	{
		private: true,
		path: '/:locale/shop/',
		Component: lazy(() => import('./shopForm/views/ShopForm'))
	},
	{
		private: true,
		path: '/:locale/shop/:action/*',
		Component: lazy(() => import('./shopForm/views/ShopForm'))
	},
	{
		private: true,
		path: '/:locale/profile/',
		Component: lazy(() => import('./profileEdit/views/Profile'))
	},
	{
		private: true,
		path: '/:locale/profile/:page/*',
		Component: lazy(() => import('./profileEdit/views/Profile'))
	},
	{
		private: true,
		path: '/:locale/orders/',
		Component: lazy(() => import('./orders/views/Orders'))
	},
	{
		private: true,
		path: '/:locale/orders/:type/',
		Component: lazy(() => import('./orders/views/Orders'))
	},
	{
		private: true,
		path: '/:locale/orders/:type/:id/*',
		Component: lazy(() => import('./orders/views/Orders'))
	},
	{
		path: '/:locale/contact/*',
		Component: lazy(() => import('./contact/views/Contact'))
	},
	{
		path: '/:locale/faq/',
		Component: lazy(() => import('./faq/views/Faq'))
	},
	{
		path: '/:locale/faq/:category/*',
		Component: lazy(() => import('./faq/views/Faq'))
	},
	{
		path: '/:locale/faq/:category/:question/*',
		Component: lazy(() => import('./faq/views/Faq'))
	},
	{
		path: '/:locale/terms/*',
		Component: lazy(() => import('./terms/views/Terms'))
	},
	{
		private: true,
		path: '/:locale/chat/',
		Component: lazy(() => import('./chat/views/Chat'))
	},
	{
		private: true,
		path: '/:locale/chat/:status/*',
		Component: lazy(() => import('./chat/views/Chat'))
	},
	{
		private: true,
		path: '/:locale/mycart/*',
		Component: lazy(() => import('./myCart/views/MyCart'))
	},
	{
		private: true,
		path: '/:locale/checkout/',
		Component: lazy(() => import('./checkout_new/views/CheckOutLib'))
	},
	{
		private: true,
		path: '/:locale/checkout/:id/',
		Component: lazy(() => import('./checkout_new/views/CheckOutLib'))
	},
	{
		private: true,
		path: '/:locale/checkout/:id/:type/',
		Component: lazy(() => import('./checkout_new/views/CheckOutLib'))
	},
	{
		private: true,
		path: '/:locale/checkout/:id/:type/:groupID/*',
		Component: lazy(() => import('./checkout_new/views/CheckOutLib'))
	},
	{
		private: true,
		path: '/:locale/address/',
		Component: lazy(() => import('./address/views/Address'))
	},
	{
		private: true,
		path: '/:locale/address/:action/',
		Component: lazy(() => import('./address/views/Address'))
	},
	{
		private: true,
		path: '/:locale/address/:action/:id/*',
		Component: lazy(() => import('./address/views/Address'))
	},
	{
		private: true,
		path: '/:locale/account/',
		Component: lazy(() => import('./account/views/Account'))
	},
	{
		private: true,
		path: '/:locale/account/:action/',
		Component: lazy(() => import('./account/views/Account'))
	},
	{
		private: true,
		path: '/:locale/account/:action/:id/*',
		Component: lazy(() => import('./account/views/Account'))
	},
	{
		private: true,
	  path: '/:locale/notifications/',
	  Component: lazy(() => import('./notifications/views/NotificationsList'))
	},
	{
		private: true,
	  path: '/:locale/notifications/:id/*',
	  Component: lazy(() => import('./notifications/views/NotificationsList'))
	},
	{
		private: true,
		path: '/:locale/mygroups/',
		Component: lazy(() => import('./mygroups/views/MyGroups'))
	},
	{
		private: true,
		path: '/:locale/mygroups/:id/*',
		Component: lazy(() => import('./mygroups/views/MyGroups'))
	},
	{
		path: '/:locale/product/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/category/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/category/subcategory/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/:category/:subcategory/:targetcategory/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/:category/:subcategory/:targetcategory/:forthcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/:sixthcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/:sixthcat/:seventhcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/product/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/:sixthcat/:seventhcat/:seotitle/*',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		private: true,
		path: '/:locale/myproducts/*',
		Component: lazy(() => import('./myproducts/views/MyProductsLib.js')),
		state: {
			'productId': true
		}},
	{
		private:true,
		path: '/:locale/favorites/*',
		Component: lazy(() => import('./favorites/views/Favorites'))
	},
	{
		path: '/:locale/user/*',
		Component: lazy(() => import('./auth/views/Auth'))
	},
	{
		path: '/:locale/auth-user/*',
		Component: lazy(() => import('./auth/views/Auth'))
	},
	{
		private: true,
		path: '/:locale/finances/',
		Component: lazy(() => import('./finances/views/Finances'))
	},
	{
		private: true,
		path: '/:locale/finances/:page/*',
		Component: lazy(() => import('./finances/views/Finances'))
	},
	{
		private: false,
		path: '/:locale/subscriptions/*',
		Component: lazy(() => import('./subscriptions/views/Subscriptions'))
	},
	{
		private: true,
		path: '/:locale/mytradein/',
		Component: lazy(() => import('./mytradein/views/MyTradeIn'))
	},
	{
		private: true,
		path: '/:locale/mytradein/:id/*',
		Component: lazy(() => import('./mytradein/views/MyTradeIn'))
	},
	{
		path: '/:locale/tradein/',
		Component: lazy(() => import('./tradein/views/TradeIn'))
	},
	{
		path: '/:locale/tradein/:nav/*',
		Component: lazy(() => import('./tradein/views/TradeIn'))
	},
	{
		path: '/:locale/rules/',
		Component: lazy(() => import('./rules/views/Rules'))
	},
	{
		path: '/:locale/rules/:page/*',
		Component: lazy(() => import('./rules/views/Rules'))
	},
	{
		private: true,
		path: '/:locale/mypage/',
		Component: lazy(() => import('./mypage/views/MyPage'))
	},
	{
		private: true,
		path: '/:locale/mypage/:type/*',
		Component: lazy(() => import('./mypage/views/MyPage'))
	},
	{
		path: '/:locale/safetyLanding/*',
		Component: lazy(() => import('./safetyLanding/views/SafetyLanding'))
	},
	{
		private: true,
		path: '/:locale/offer-price/*',
		Component: lazy(() => import('./offerPrice/views/OfferPriceLib.js'))
	},
	{
		private: true,
		path: '/:locale/offer-price/:type/',
		Component: lazy(() => import('./offerPrice/views/OfferPriceLib.js'))
	},
	{
		private: true,
		path: '/:locale/offer-price/:type/:id/*',
		Component: lazy(() => import('./offerPrice/views/OfferPriceLib.js'))
	},
	{
		path: '/:locale/iyide-gayide/*',
		Component: lazy(() => import('./onlinelanding/OnlineLanding'))
	},
	{
		path: '/:locale/security-check-app/*',
		Component: lazy(() => import('./securityCheckApp/views/SecurityCheckApp'))
	},
	{
		path: '/:locale/pr/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/:subcategory/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/:subcategory/:targetcategory/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/:subcategory/:targetcategory/:forthcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/:sixthcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/:sixthcat/:seventhcat/',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},
	{
		path: '/:locale/pr/:prId/:category/:subcategory/:targetcategory/:forthcat/:fifthcat/:sixthcat/:seventhcat/:seotitle/*',
		Component: lazy(() => import('./productFull/views/ProductFullLib.js'))
	},

	{
		path : '/:locale/add/qr-upload/:id',
		Component: lazy(() => import('./qr-upload/views/QrUpload')),
		Layout: EmptyLayout,
	},

	{
		private: true,
		path : '/:locale/mycards',
		Component: lazy(() => import('modules/myCards/views/MyCards')),
	},

	{
		path : '/:locale/process-card',
		Component: lazy(() => import('modules/myCards/views/ProcessCard')),
		Layout: EmptyLayout,
	}

	
];
