import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import { EmptyStates } from 'components'
import { AppDataContext } from 'contexts/appData';
const OrdersEmpty = (props) => {
    const { getLang } = useContext(AppDataContext);
    const Icon = () => {
        return (
            <div className=""></div>
        )
    }

    const Title = () => {
        return (
            <div className="mb-16px font-size-16 font-bold-caps text-dark">
                {props.title}
            </div>
        )
    }
    const Description = () => {
        return (
            <div className="max-width-356px text-center font-size-14 font-base text-blue-gray">
                 {props.description}
            </div>
        )
    }
    const Actions = () => {
        return (
            <Link reloadDocument to={`/${getLang}/search?Online=1`} className="btn btn-primary px-56px py-20px text-white font-size-13 font-bold-caps border-radius-md mt-40px">
                   {props.actions}
            </Link>
        )
    }
    return <EmptyStates {...{Icon, Title, Description, Actions,  classIcon:"svg-i empty-orders mb-24px" , classContainer:'mt-8px', classInner: "px-15px border-radius-md bg-white pt-100px pb-200px border-radius-sm-none mt-24px"}} />
}

export default OrdersEmpty